<template lang="pug">

.vc-product-bonus-reward-info(v-if="isRewardsProgramEnabled")
  .info-unit.price
    .label {{ attributeLocaleText('product', 'bonus_reward') }}
    .price
      .icon
        i.fa.fa-product-hunt
      span.bonus {{ bonusReward }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    selectedVariant: {
      type: Object,
      required: true,
    },

    quantity: {
      type: Number,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isRewardsProgramEnabled() {
      return this.isUserSignedIn && Number(this.selectedVariant.bonus_reward) > 0
    },

    bonusReward() {
      return this.moneyFromAmount(Math.round(this.selectedVariant.bonus_reward * this.quantity)).format()
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
