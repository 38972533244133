<template lang="pug">

.vc-product-info-card
  .header-bar
    .info-column
      h1.name {{ product.name }}
      a.brand(:href="brandLink"
              v-if="product.brand_id") {{ product.brand_name }}
      .spec-descriotion(v-if="isVariantsLoaded && !!selectedVariant.description")
        .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

  .options(v-if="variants.length > 0")
    .variant-selector.option-unit(v-if="variants.length > 1")
      .label {{ attributeLocaleText('order/item', 'variant') }}
      b-dropdown.content(v-model="selectedVariantId"
                         @change="variantChangedHandler")
        .variants-dropdown(role="button"
                           slot="trigger")
          .variant-name {{ selectedVariant.displayName() }}
          .clickable-indicator
            .icon
              i.fa.fa-chevron-down

        b-dropdown-item(v-for="variant in variants"
                        :key="variant.id"
                        :value="variant.id")
          span {{ variant.name }}
          span.message(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
    .quantity-selector.option-unit
      .label {{ attributeLocaleText('order/item', 'quantity') }}
      quantity-picker.content(:quantity.sync="quantity"
                              :current-stock="currentStock")

  .offers-info(v-if="variants.length > 0")
    .info-unit.price
      span {{ attributeLocaleText('product', 'price') }}
      .price
        span.type(v-if="product.hasDiscount()"
                  data-currency="TWD") {{ toMoney(selectedVariant.original_price).format() }}
        span.type(data-currency="TWD") {{ toMoney(selectedVariant.consumer_price).format() }}
    .info-unit.price
      .label {{ attributeLocaleText('product', 'sub_total') }}
      .price
        span.type(data-currency="TWD") {{ subTotal.format() }}
    bonus-reward-info(:selected-variant="selectedVariant"
                      :quantity="quantity")

  .action-bar
    collect-button(:product="product")
    template(v-if="isPurchasable")
      .button.is-odd.is-outlined(:class="{ 'is-loading': isProcessing }"
                                 @click="buyNow") {{ actionLocaleText('buy_now') }}
      .button.is-odd(:class="{ 'is-loading': isProcessing }"
                     @click="addToCart") {{ actionLocaleText('add_to_cart') }}
    template(v-else)
      .button.is-light(disabled) {{ copyLocaleText('stock_status.no_stock') }}

</template>

<script>
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import Form from 'odd-form_object'
import BonusRewardInfo from './bonus-reward-info.vue'
import CollectButton from './collect-button.vue'
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    BonusRewardInfo,
    CollectButton,
    Countdown,
  },

  mixins: [productVariantSelectableMixin],

  props: {
    variants: {
      type: Array,
      required: true,
    },
  },

  // data() {
  //   return {}
  // },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    selectedVariant() {
      return this.variants.find((variant) => variant.id === this.selectedVariantId)
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedVariant.id,
            quantity: this.quantity,
          },
        },
      }
    },
  },

  watch: {
    masterVariant(newValue) {
      this.selectedVariantId = newValue.id
    },

    selectedVariantId() {
      this.quantity = 1
    },
  },

  // created() {},
  mounted() {
    this._setPaddingBottom()
    this._initializeCartService()
  },

  methods: {
    variantChangedHandler() {
      this.$nextTick(() => this.$emit('variant-changed', this.selectedVariant))
    },

    _setPaddingBottom() {
      const actionBarHeight = this.$el.querySelector('.action-bar').offsetHeight

      this.$el.style.paddingBottom = `${actionBarHeight}px`
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.quantity = 1
          this.selectedVariantId = this.masterVariant.id
        })
        .catch((errors) => {
          this.isProcessing = false
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          this.isProcessing = false
        })
    },

    countdownEndedHandler() {
      setTimeout(() => {
        this.$emit('countdown-end')
      }, 3000)
    },
  },
}
</script>
