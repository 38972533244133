<template lang="pug">

.vc-dashboard-bonus-points-box
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .content-wrapper
    //- HEADER
    .content-header
      .icon
        i.fa.fa-product-hunt
      .text
        span {{ attributeLocaleText('user', 'bonus_credit') }}
    //- BODY
    .content-body
      .bonus-credit-info(v-if="currentUserData.isDataLoaded()")
        .label {{ modelNameLocaleText('user/bonus_credit_transaction') }}
        .bonus-credit {{ moneyFromAmount(currentUserData.bonus_credit).format() }}
      .credit-transations
        .label {{ attributeLocaleText('user/bonus_credit_transaction', 'credit_balance') }}
        a.credit-transaction(v-for="bonusCreditTransaction in bonusCreditTransactions"
                           :key="bonusCreditTransaction.id"
                           :href="`/user/orders/${bonusCreditTransaction.order_token}`")
          .order-number {{ bonusCreditTransaction.order_number }}
          .credit-amount
            .icon
              i.fa.fa-product-hunt
            span {{ moneyFromAmount(bonusCreditTransaction.credit_amount).format() }}

  odd-pagination(:total="totalCount"
                 :current.sync="currentPage"
                 :per-page="pageSize"
                 size="is-small"
                 order="is-centered"
                 :rounded="true"
                 @change="onPageChange")

</template>

<script>
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import OddPagination from '../common/odd-pagination.vue'

export default {
  components: {
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'userBonusCreditTransactions',
      sortOrder: 'desc',
      sortField: 'created_at',
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    },

    bonusCreditTransactions() {
      return this.$store.getters['userBonusCreditTransactions/all']
    },

    hasBonusCreditTransactions() {
      return this.bonusCreditTransactions.length > 0
    }
  },
  created() {
    if (!this.currentUserData.isDataLoaded()) this.$store.dispatch('users/find', this.currentUser.id)
    this.fetchingInitialData()
  },

  // mounted() {},
  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch('users/fetchBonusCreditTransactions', {
        model: this.currentUserData,
        options: options,
      })
    },

    updateQueryString(options) {},
  },
}
</script>
