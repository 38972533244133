import BlogSection from '../../components/blog_category/blog-section.vue'
import BlogCard from '../../components/blog/card.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'

export default {
  name: 'blog-index-page-container',

  components: {
    BlogSection,
    BlogCard,
    OddPagination,
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      blogIds: null,
      resourceType: 'promotionalArticles',
      currentPage: 1,
      pageSize: 12,
      sortField: 'published_at',
      selectedCategoryId: undefined,
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    },

    isCategorySelected() {
      return this.selectedCategoryId !== undefined
    },

    selectedCategory() {
      return this.$store.getters['categorySystems/findCategory'](this.selectedCategoryId)
    },

    allCategories() {
      return this.$store.getters['categorySystems/allCategories']()
    },

    blogs() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].filter((blog) => this.blogIds.includes(blog.id))
    },

    additionalOptions() {
      return {
        category: this.selectedCategoryId,
      }
    },
  },

  // created() {},

  mounted() {
    this._ensureCategoriesLoaded()
  },

  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        category: this.currentQueryString['category'] || this.selectedCategoryId,
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      if (options.category) this.selectedCategoryId = parseInt(options.category)
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category) result += `&category=${options.category}`

      return result
    },

    fetchData(options) {
      this.currentPage = options.pageNumber

      if (this.selectedCategoryId) {
        this._fetchCategoryBlogs(options)
      } else {
        this._fetchBlogs(options)
      }
    },

    _ensureCategoriesLoaded() {
      if (this.categories) return this.fetchingInitialData()

      this.$store
        .dispatch('categorySystems/fetchCategoriesByType', {
          type: 'promotional_article',
          sort: 'created_at',
        })
        .then(() => {
          this.fetchingInitialData()
        })
    },

    _fetchCategoryBlogs(options) {
      this.$store
        .dispatch('categorySystems/fetchCategoryPromotionalArticles', {
          model: this.selectedCategory,
          options: Object.assign(options, {
            noReplace: true,
            sub_categories_included: true,
          }),
        })
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
        })
    },

    _fetchBlogs(options) {
      this.$store
        .dispatch(
          'promotionalArticles/latestArticles',
          Object.assign(options, {
            usage: 'blog',
            noReplace: true,
          })
        )
        .then((response) => {
          this.blogIds = response.data.data.map((blog) => blog.id)
        })
    },
  },
}
