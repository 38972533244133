import SlideshowContainer from '../../../application/js/components/common/slideshow-container.vue'
import InfoLinkUseTypeContainer from '../components/info_link/use-type-container.vue'
import BlogSection from '../components/blog_category/blog-section.vue'
import BlogCategoryBasicMixins from '../components/mixins/blog_category_basic_mixin.js'
import HotBlogs from '../components/blog/hot-blogs.vue'

export default {
  name: 'blog-page-container',

  components: {
    SlideshowContainer,
    InfoLinkUseTypeContainer,
    BlogSection,
    HotBlogs,
  },

  mixins: [BlogCategoryBasicMixins],

  // props: {},

  // data() {
  //   return {}
  // },

  // computed: {},

  // created() {},

  // mounted() {},

  // methods: {}
}
