<template lang="pug">

section.vc-blog-category-blog-section
  .blog-section-header
    .odd-container
      h2.section-title.-larger {{ category.name }}
  .blog-section-body
    b-loading(:active.sync="isLoading"
                :is-full-page="false")

    .odd-container
      .half
        blog-card.large(v-if="firstBlog"
                        :blog="firstBlog")
      .half
        blog-card.small(v-for="blog in blogs"
                        :key="blog.id"
                        :blog="blog")

</template>

<script>
import BlogCard from '../blog/card.vue'

export default {
  components: {
    BlogCard,
  },

  // mixins: [],

  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      blogIds: null,
      isLoading: true,
    }
  },

  computed: {
    firstBlog() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].find((blog) => this.blogIds[0] === blog.id)
    },

    blogs() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].filter((blog) =>
          this.blogIds.slice(1, 4).includes(blog.id)
        )
    },
  },

  created() {
    this.$store
      .dispatch('categorySystems/fetchCategoryPromotionalArticles', {
        model: this.category,
        options: {
          pageNumber: 1,
          pageSize: 4,
          sort: '-view_count',
          noReplace: true,
          sub_categories_included: true,
        },
      })
      .then((response) => {
        this.blogIds = response.data.data.map((blog) => blog.id)
        this.isLoading = false
      })
  },

  // mounted() {},
  // methods: {}
}
</script>
