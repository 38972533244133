import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import QuantityPicker from '../product/quantity-picker.vue'

export default {
  components: {
    QuantityPicker,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedVariantId: null,
      quantity: 1,
      cartService: null,
      isProcessing: false,
    }
  },

  computed: {
    isVariantsLoaded() {
      return typeof this.selectedVariantId === 'string'
    },

    isPurchasable() {
      return this.selectedVariant && this.selectedVariant.stock > 0
    },

    subTotal() {
      return this.toMoney(this.selectedVariant.consumer_price).multiply(this.quantity)
    },

    masterVariant() {
      return this.variants.find((variant) => variant.is_master === true)
    },

    currentStock() {
      return this.selectedVariant.stock || 0
    },
  },

  mounted() {
    // console.log('please add `this._initializeCartService()` to mounted hook.')
  },

  methods: {
    _initializeCartService() {
      this.cartService = new ShoppingCartService(this.$vlf, this.$store)
    },

    addToCart() {
      console.log('please implement method `#addToCart` in component.')
    },

    buyNow() {
      console.log('please implement method `#buyNow` in component.')
    },

    _callAddToCartMethod() {
      this.isProcessing = true

      return this.cartService
        .addItemToCart(this.requestBody)
        .then(() => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('product_added_to_cart_successfully'),
          ])
        })
        .then((_) => {
          return this._tracingAddToCartEvent()
        })
    },

    _tracingAddToCartEvent() {
      return this.$store
        .dispatch('gaOperation', [
          [
            'ec:addProduct',
            {
              id: this.selectedVariant.sku,
              name: this.selectedVariant.product_name,
              brand: this.product.brand_name,
              variant: this.selectedVariant.name,
              price: this.toMoney(this.selectedVariant.consumer_price).amount,
              quantity: this.quantity,
            },
          ],
          ['ec:setAction', 'add'],
          [
            'send',
            {
              hitType: 'event',
              eventCategory: 'Ecommerce',
              eventAction: 'Add to Cart',
            },
          ],
        ])
        .then((_) => {
          return this.$store.dispatch('pixelOperation', [
            [
              'track',
              'AddToCart',
              {
                content_ids: this.selectedVariant.sku,
                content_name: this.selectedVariant.product_name,
                content_type: 'Product',
                currency: 'TWD',
                value: this.toMoney(this.selectedVariant.consumer_price).amount,
                quantity: this.quantity,
              },
            ],
          ])
        })
    },
  },
}
