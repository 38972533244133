<template lang="pug">

transition(enter-active-class="animated slideInLeft"
           leave-active-class="animated slideOutLeft")
  .vc-common-blog-mobile-menu(v-if="mobileMenuStates['isMobileMenuActive']")
    //- Root Menu
    transition(enter-active-class="animated slideInLeft"
               leave-active-class="animated slideOutLeft")
      .menu-wrapper.root
        ul.mobile-menu-items
          li.mobile-menu-item(v-for="category in categories"
                              :key="category.id")
            a.link(href="#"
                  :class="{ 'is-active': currentCategory && currentCategory.id === category.id }"
                  @click.prevent="selectCategory(category)")
              span.text {{ category.name }}
              .icon(v-if="subCategories(category).length > 0")
                i.fa.fa-chevron-right
            transition(enter-active-class="animated fadeIn")
              ul.sub-categories(v-if="subCategories(category).length > 0 && currentCategory && currentCategory.id === category.id")
                li.sub-category-unit
                  a.link.selected-top-level-category(href="#"
                                                    @click.prevent="linkTo(category)")
                    span.name {{ copyLocaleText('all_x', { x: category.name }) }}
                li.sub-category-unit(v-for="subCategory in subCategories(category)"
                                    :key="subCategory.id")
                  a.link(href="#"
                        @click.prevent="linkTo(subCategory)")
                    span.name {{ subCategory.name }}

</template>

<script>
import BlogCategoryBasicMixins from '../mixins/blog_category_basic_mixin.js'

export default {
  // components: {},
  mixins: [BlogCategoryBasicMixins],
  // props: {},

  data() {
    return {
      currentCategory: undefined,
      currentSubCategory: undefined,
    }
  },

  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },
  },

  // created() {},

  // mounted() {},

  methods: {
    selectCategory(category) {
      if (this.subCategories(category).length === 0) return this.linkTo(category)

      if (this.currentCategory && this.currentCategory.id === category.id) {
        this.currentCategory = undefined
      } else {
        this.currentCategory = category
      }
    },

    linkTo(category) {
      Turbolinks.visit(`/blogs?category=${category.id}`)
    },
  },
}
</script>
