import debounce from 'lodash.debounce'
import MobileMenu from '../components/common/blog-mobile-menu.vue'
import CategoryMenu from '../components/common/blog-category-menu.vue'
import SearchButton from '../components/common/blog-search-button.vue'

const MOBILE_MENU_BREAK_POINT = 769

export default {
  name: 'site-navbar-container',

  components: {
    MobileMenu,
    CategoryMenu,
    SearchButton,
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      prevScrollPosition: 0,
      debounceShowBoxShadowOnScrollUp: null,
      debounceAutoHideNavbarAtScrollDown: null,
      searchText: undefined,
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (this.$store.getters['windowWidth'] || window.innerWidth) < MOBILE_MENU_BREAK_POINT
    },

    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    menuTriggerClass() {
      return {
        'is-active': this.mobileMenuStates['isMobileMenuActive'],
        'is-child-level': this.isChildLevel,
      }
    },

    isChildLevel() {
      let mobileMenuStates = this.mobileMenuStates

      return mobileMenuStates['isSkippingRootMenuToClose']
        ? !!mobileMenuStates['selectedTopLevelCategoryId']
        : mobileMenuStates['isCategoriesSelectorActive']
    },
  },

  // created() {},

  mounted() {
    this.debounceShowBoxShadowOnScrollUp = debounce(this.showBoxShadowOnScrollUp, 100, {
      leading: true,
      trailing: true,
    })
    this.debounceAutoHideNavbarAtScrollDown = debounce(this.autoHideNavbarAtScrollDown, 100, {
      leading: true,
      trailing: true,
    })

    window.addEventListener('scroll', this.debounceShowBoxShadowOnScrollUp)
    window.addEventListener('scroll', this.debounceAutoHideNavbarAtScrollDown)

    // this._restoreSearchTextFromUrl()
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceShowBoxShadowOnScrollUp)
    window.removeEventListener('scroll', this.debounceAutoHideNavbarAtScrollDown)
  },

  methods: {
    mobileMenuTriggerHandler() {
      if (this.isChildLevel) return this.prevButtonClickedHandler()

      let mobileMenuStates = this.mobileMenuStates

      // 關閉 或從 nav 打開 menu 時，
      // isSkippingRootMenuToClose 應為 false, 防止從 NavBar 進到 category list 時返回卻直接關掉 Menu;
      // isCategoriesSelectorActive 應為 false, 讓 menuTriggerClass 保持在正確的狀態
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(mobileMenuStates, {
          isMobileMenuActive: !mobileMenuStates['isMobileMenuActive'],
          isCategoriesSelectorActive: false,
          isSkippingRootMenuToClose: false,
        })
      )
    },

    prevButtonClickedHandler() {
      let menuStates = Object.assign(this.mobileMenuStates)

      this.mobileMenuStates['selectedTopLevelCategoryId']
        ? (menuStates['selectedTopLevelCategoryId'] = null)
        : (menuStates['isCategoriesSelectorActive'] = false)

      this.$nextTick(() => {
        this.$store.dispatch('toggleMobileMenu', menuStates)
      })
    },

    categoriesLoadedHandler() {
      document.documentElement.setAttribute('style', `padding-top: ${this.$el.offsetHeight}px`)
    },

    autoHideNavbarAtScrollDown() {
      const currentScrollPosition = window.pageYOffset
      const navBar = this.$el

      if (currentScrollPosition > this.prevScrollPosition && currentScrollPosition > navBar.scrollHeight) {
        if (this.mobileMenuStates['isMobileMenuActive']) return // dont hide menu if mobile menu activated

        navBar.setAttribute('style', `top: -${this.$el.offsetHeight}px`)
      } else {
        navBar.removeAttribute('style', 'top')
      }
      this.prevScrollPosition = currentScrollPosition
    },

    showBoxShadowOnScrollUp() {
      if (window.pageYOffset > 0) {
        document.querySelector('.blog-site-navbar').classList.add('has-shadow')
      } else {
        document.querySelector('.blog-site-navbar').classList.remove('has-shadow')
      }
    },
  },
}
