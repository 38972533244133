<template lang="pug">

.vc-common-blog-search-button
  b-dropdown(:triggers="['click', 'hover']"
            position="is-bottom-left"
            hoverable)
    a.link(slot="trigger"
           href="#"
           @click.prevent="")
      .icon.search
        .fa.fa-search
    b-dropdown-item(custom)
      b-field(:label="actionLocaleText('search')")
        input.input(type="text"
                    v-model="searchText"
                    @keydown.enter="search"
                    @compositionend="composing=false"
                    @compositionstart="composing=true")

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      modalActived: false,
      searchText: '',
      composing: false,
    }
  },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    openSearchModal() {
      this.modalActived = true
    },

    search() {
      if (this.composing) return

      Turbolinks.visit(encodeURI(`/blogs?q[title_cont]=${this.searchText}`))
    },
  },
}
</script>
