<template lang="pug">

.vc-contact-form
  .part
    .form-section.has-sub-fields
      b-field.is-full(:label="attributeLocaleText('contact', 'name')"
                      :type="errors.errorClassAt('name')"
                      :message="errors.get('name')")
        b-input(v-model="form.name"
                autocomplete="name"
                @input="errors.clear('name')")
      b-field.is-full(:label="attributeLocaleText('contact', 'phone')"
                      :type="errors.errorClassAt('phone')"
                      :message="errors.get('phone')")
        b-input(type="tel"
                v-model="form.phone"
                autocomplete="tel"
                @input="errors.clear('phone')")
      b-field.is-full(:label="attributeLocaleText('contact', 'address')"
                      :type="errors.errorClassAt('address')"
                      :message="errors.get('address')")
        b-input(v-model="form.address"
                autocomplete="address"
                @input="errors.clear('address')")
      b-field.is-full(:label="attributeLocaleText('contact', 'email')"
                      :type="errors.errorClassAt('email')"
                      :message="errors.get('email')")
        b-input(type="email"
                autocomplete="email"
                v-model="form.email"
                @input="errors.clear('email')")
      b-field(:label="attributeLocaleText('contact', 'stories')"
              :type="errors.errorClassAt('stories')"
              :message="errors.get('stories')")
        b-select(v-model="form.stories"
                 @input="errors.clear('stories')")
          option(v-for="(value, index) in humberNumberArray(100)"
                 :key="index")
            | {{ value + 1 }}
      b-field(:label="attributeLocaleText('contact', 'building_age')"
              :type="errors.errorClassAt('building_age')"
              :message="errors.get('building_age')")
        b-select(v-model="form.building_age"
                 @input="errors.clear('building_age')")
          option(v-for="(value, index) in humberNumberArray(100)"
                 :key="index")
            | {{ value + 1 }}
      b-field(:label="attributeLocaleText('contact', 'has_shaft')"
              :type="errors.errorClassAt('has_shaft')"
              :message="errors.get('has_shaft')")
        b-select(v-model="form.has_shaft"
                 @input="errors.clear('has_shaft')")
          option(v-for="(value, index) in yesNoOptions"
                 :key="index")
            | {{ value }}
      b-field(:label="attributeLocaleText('contact', 'building_structure')"
              :type="errors.errorClassAt('building_structure')"
              :message="errors.get('building_structure')")
        b-select(v-model="form.building_structure"
                 @input="errors.clear('building_structure')")
          option(v-for="(value, index) in buildingStructureOptions"
                 :key="index")
            | {{ value }}
      b-field(:label="attributeLocaleText('contact', 'has_waterproofing')"
              :type="errors.errorClassAt('has_waterproofing')"
              :message="errors.get('has_waterproofing')")
        b-select(v-model="form.has_waterproofing"
                 @input="errors.clear('has_waterproofing')")
          option(v-for="(value, index) in yesNoOptions"
                 :key="index")
            | {{ value }}
  .part
    .form-section.has-sub-fields
      b-field.is-full(:label="attributeLocaleText('contact', 'description')"
                      :type="errors.errorClassAt('description')"
                      :message="errors.get('description')")
        b-input(type="textarea"
                rows="8"
                v-model="form.description"
                @input="errors.clear('description')")
      b-field.is-full(:label="attributeLocaleText('contact', 'picture')"
                      :type="errors.errorClassAt('picture')"
                      :message="errors.get('picture') || '可以上傳一張 jpg, png, gif 檔'")
        b-upload(v-model="form.picture"
                 @input="errors.clear('picture')"
                  accept="image/*"
                 expanded)
          a.button.is-fullwidth
            .icon
              i.fa.fa-upload
            span {{ (form.picture && form.picture.name) || '上傳照片' }}
      .field.is-full
        #g-recaptcha(data-sitekey="6LdxrCEaAAAAAAn0hxRQqz_eV54NHWWy1p87kZM0")
      .field.is-full
        button.submit.button.is-fullwidth.is-odd(:disabled="!this.recaptchaPassed || isLoading"
                                                 @click="submitForm")
          | 送出表單
</template>

<script>
import Form from 'odd-form_object'
import Contact from '../../../../shared/resource_models/contact'
import axios from 'axios'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  data() {
    return {
      form: new Form(new Contact()),
      recaptchaElementID: '#g-recaptcha',
      recaptchaPassed: false,
    }
  },

  computed: {
    submitDisabled() {
      return !this.recaptchaPassed
    },

    errors() {
      return this.form.model.errors
    },

    isLoading() {
      return this.$store.getters['contacts/isLoading']
    },

    yesNoOptions() {
      return ['是', '否']
    },

    buildingStructureOptions() {
      return ['紅磚', '混凝土', '空心磚']
    },
  },
  // created() {},
  mounted() {
    this.insertReCaptcha()
  },
  methods: {
    humberNumberArray(number) {
      return Array.from(Array(number).keys())
    },

    insertReCaptcha() {
      console.log(`document.querySelector(this.recaptchaElementID)`, document.querySelector(this.recaptchaElementID))
      if (!document.querySelector(this.recaptchaElementID)) return
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute(
        'src',
        'https://www.google.com/recaptcha/api.js?onload=renderReCaptcha&render=explicit'
      )
      recaptchaScript.setAttribute('async', '')
      recaptchaScript.setAttribute('defer', '')
      document.head.appendChild(recaptchaScript)

      window.renderReCaptcha = this.renderReCaptcha
    },
    renderReCaptcha() {
      let recaptchaElement = document.querySelector(this.recaptchaElementID)
      window.grecaptcha.render(recaptchaElement, {
        sitekey: recaptchaElement.dataset.sitekey,
        callback: this.recaptchaResponse,
        'expired-callback': this.recaptchaExpired,
        hl: 'zh-TW',
      })
    },

    recaptchaResponse(response) {
      axios
        .post('/api/v1/web/recaptcha_verification', {
          response: response,
        })
        .then((response) => {
          if (response.data.status) {
            this.recaptchaPassed = true
          } else {
            window.grecaptcha.reset()
          }
        })
    },

    recaptchaExpired() {
      this.recaptchaPassed = false
    },

    submitForm() {
      let formData = new FormData()

      Object.keys(this.form.data()).map((key) => {
        formData.append(`contact[${key}]`, this.form[key] || '')
      })

      this.$store.dispatch('contacts/save', { model: this.form.model, formData }).then(() => {
        this.$store.dispatch('addFlashMessage', [
          'success',
          this.messageLocaleText(`resource_added_successfully`, { resource: this.modelNameLocaleText('contact') }),
        ])
        window.grecaptcha.reset()
        this.recaptchaPassed = false

        setTimeout(() => {
          Turbolinks.visit(`/`)
        }, 2000)
      })
    },
  },
}
</script>
