import ContactForm from '../../components/contact/form.vue'

export default {
  name: 'contact-show-page-container',

  components: {
    ContactForm,
  },

  // mixins: [],

  // props: {},

  data() {
    return {}
  },

  computed: {
    isLoading() {
      return this.$store.getters['contacts/isLoading']
    },
  },

  // created() {},

  mounted() {},

  methods: {},
}
