<template lang="pug">

.vc-blog-latest-blogs
  .blog-section-header.-border-left
    h2.section-title.-justify-left {{ copyLocaleText('latest_blog') }}
    .tools-wrapper
      .icon
        i.fa.fa-chevron-right
  .blog-section-body
    b-loading(:active.sync="isLoading"
                :is-full-page="false")

    blog-card.extra-small(v-for="blog in blogs"
                          :key="blog.id"
                          :blog="blog")

</template>

<script>
import BlogCard from './card.vue'

export default {
  components: {
    BlogCard,
  },

  // mixins: [],

  props: {
    blog: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      blogIds: null,
      isLoading: true,
    }
  },

  computed: {
    blogs() {
      if (this.blogIds)
        return this.$store.getters['promotionalArticles/all'].filter((blog) => this.blogIds.includes(blog.id))
    },
  },

  created() {
    this.$store
      .dispatch('promotionalArticles/latestArticles', {
        pageNumber: 1,
        pageSize: 4,
        sort: '-published_at',
        usage: 'blog',
        search: {
          id_not_eq: this.blog.id,
        },
      })
      .then((response) => {
        this.blogIds = response.data.data.map((blog) => blog.id)
        this.isLoading = false
      })
  },
  // mounted() {},
  // methods: {}
}
</script>
