import * as types from './mutation-types'
import PromotionalArticle from '../../resource_models/promotional_article'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromotionalArticle.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTIONAL_ARTICLES_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit,
            },
            params: options,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromotionalArticle.find(id)
      .then((response) => {
        commit(types.GET_PROMOTIONAL_ARTICLE_SUCCESS, response)
        dispatch('categorySystems/receiveCategoriesFromRelationships', response, {
          root: true,
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit,
            },
            params: id,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTIONAL_ARTICLE_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTIONAL_ARTICLE_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const saveWithFormData = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'saveWithFormData')

  return new Promise((resolve, reject) => {
    model
      .saveWithFormData(formData)
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTIONAL_ARTICLE_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTIONAL_ARTICLE_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveWithFormData,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTIONAL_ARTICLE_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const toggleAvailability = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleAvailability')

  return new Promise((resolve, reject) => {
    model
      .toggleAvailability()
      .then((response) => {
        commit(types.UPDATE_PROMOTIONAL_ARTICLE_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleAvailability,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const latestArticles = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'latestArticles')

  return new Promise((resolve, reject) => {
    PromotionalArticle.all(options)
      .then(response => {
        if (options.replace) {
          commit(types.FETCH_PROMOTIONAL_ARTICLES_SUCCESS, response)
        } else {
          commit(types.GET_RELATED_PROMOTIONAL_ARTICLES_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: latestArticles,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTIONAL_ARTICLES_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_PROMOTIONAL_ARTICLES_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTIONAL_ARTICLE_SUCCESS, response)

    resolve(response)
  })
}
