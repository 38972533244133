<template lang="pug">

section.vc-product-variants-selector
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  .content-wrapper
    //- HEADER
    .content-header
      .picture-box
        img.picture(:src="displayPicture")
      .product-info
        h1.name {{ product.name }}
        a.brand(:href="brandLink"
                v-if="product.brand_id") {{ product.brand_name }}

    .spec-descriotion(v-if="isVariantsLoaded && !!selectedVariant.description")
      .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

    //- BODY
    .content-body(v-if="hasSelectableVariants")
      .variants-list
        .label {{ actionLocaleText('select_x', { x: modelNameLocaleText('product/variant') }) }}
        .variant-unit(v-if="isVariantsLoaded"
                      v-for="variant in variants"
                      :key="variant.id"
                      :class="variantSelectedClass(variant)"
                      @click="selectVariant(variant)")
          .name {{ variant.name }}
          .out-of-stock-message(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}

    //- FOOTER
    .content-footer
      quantity-picker(v-if="isVariantsLoaded"
                      :quantity.sync="quantity"
                      :current-stock="currentStock")
      .info-wrapper
        .info-unit.price
          .label {{ attributeLocaleText('product', 'price') }}
          .price
            span.type(v-if="product.hasDiscount()"
                      data-currency="TWD") {{ toMoney(selectedVariant.original_price).format() }}
            span.type(data-currency="TWD") {{ toMoney(selectedVariant.consumer_price).format() }}
        .info-unit.price
          .label {{ attributeLocaleText('product', 'sub_total') }}
          .price
            span.type(data-currency="TWD") {{ subTotal.format() }}
        bonus-reward-info(:selected-variant="selectedVariant"
                          :quantity="quantity")

      .action-bar
        template(v-if="isPurchasable")
          .button.is-odd.is-outlined(:class="{ 'is-loading': isProcessing }"
                                     @click="buyNow") {{ actionLocaleText('buy_now') }}
          .button.is-odd(:class="{ 'is-loading': isProcessing }"
                         @click="addToCart") {{ actionLocaleText('add_to_cart') }}
        template(v-else)
          .button.is-light(disabled) {{ copyLocaleText('stock_status.no_stock') }}

</template>

<script>
import BonusRewardInfo from './bonus-reward-info.vue'
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'

export default {
  components: {
    BonusRewardInfo,
  },

  mixins: [productVariantSelectableMixin],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.product.id)
    },

    selectedVariant() {
      if (this.isVariantsLoaded) {
        return this.variants.find((variant) => variant.id === this.selectedVariantId)
      } else {
        return this.product
      }
    },

    displayPicture() {
      if (this.isVariantsLoaded) return this.selectedVariant.displayPicture()
    },

    hasSelectableVariants() {
      return this.variants.length > 1
    },

    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedVariant.id,
            quantity: this.quantity,
          },
        },
      }
    },
  },
  // created() {},
  mounted() {
    if (this.variants.length === 0) {
      this.$store
        .dispatch('products/find', this.product.id)
        .then(() => {
          return (this.selectedVariantId = this.masterVariant.id)
        })
        .then(() => {
          return this.setMaxHeightToContentBody()
        })
    } else {
      this.selectedVariantId = this.masterVariant.id
      this.setMaxHeightToContentBody()
    }

    this._initializeCartService()
  },

  methods: {
    setMaxHeightToContentBody() {
      const contentBody = this.$el.querySelector('.content-body')

      if (!contentBody) return

      contentBody.style.maxHeight = `calc(100vh
                                     - 160px
                                     - ${this.$el.querySelector('.content-header').scrollHeight}px
                                     - ${this.$el.querySelector('.content-footer').scrollHeight}px)`
    },

    variantSelectedClass(variant) {
      return {
        'is-selected': this.selectedVariantId === variant.id,
      }
    },

    selectVariant(variant) {
      this.selectedVariantId = variant.id
      this.quantity = 1
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.$parent.close()
        })
        .catch((errors) => {
          this.isProcessing = false
          if (errors.response.status === 401 && errors.response.data.code === 'token_expired') {
            this.$parent.close()
          }
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$parent.close()
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          if (errors.response.status === 401 && errors.response.data.code === 'token_expired') {
            this.$parent.close()
          }
          this.isProcessing = false
        })
    },
  },
}
</script>
