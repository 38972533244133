<template lang="pug">

.vc-order-price-detail
  .wrapper
    .detail-unit(:data-minus="false")
      .label {{ attributeLocaleText('order', 'item_total') }}
      .price(data-currency="TWD"
             :data-empty="order.item_total === 0") {{ toMoney(order.item_total).format() }}
    .detail-unit(:data-minus="false")
      .label {{ attributeLocaleText('order', 'shipment_total') }}
      .price(data-currency="TWD"
             :data-empty="order.shipment_total === 0") {{ shipmentTotal }}
    .detail-unit(v-for="adjustment in adjustments"
                 :key="adjustment.id"
                 :data-minus="adjustment.amount < 0")
      .label {{ adjustment.title }}
      .price(data-currency="TWD") {{ toMoney(adjustment.amount).format() }}

    .order-total
      .label
        span {{ attributeLocaleText('order', 'total') }}
        //- br
        //- span.tax-note {{ attributeLocaleText('order', 'include_business_tax', { tax: toMoney(order.tax_total).format() }) }}
      .price(data-currency="TWD") {{ toMoney(order.total).format() }}

  .notice-message-unit
    bonus-info(:order="order")
    //- .icon
    //-   i.fa.fa-info-circle
    //- span 訂單達 $400 以上即可享免運費優惠。

</template>

<script>
import BonusInfo from './bonus-info.vue'

export default {
  components: {
    BonusInfo
  },

  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    adjustments() {
      // 不顯示贈品的 adjustment
      return this.$store.getters['orders/allAdjustments'].filter(
        (adjustment) => !this.order.gift_items.map((gift) => gift.id).includes(String(adjustment.adjustable_id))
      )
    },

    shipmentTotal() {
      if (['cart', 'items_confirmed'].includes(this.order.order_state)) return this.actionLocaleText('calculating')
      return this.toMoney(this.order.shipment_total).format()
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
