<template lang="pug">

.vc-dashboard-resource-links
  a.resource-unit(v-for="resource in resourcesList"
                  :href="resource.path")
    .icon
      i.fa(:class="resource.icon")
    .text
      span {{ resource.name }}
    //- .count
    //-   span {{ resource.count }}
  a.resource-unit(@click="showBonusPointsBox")
    .icon
      i.fa.fa-product-hunt
    .text
      span {{ attributeLocaleText('user', 'bonus_credit') }}

</template>

<script>
import BonusPointsBox from './bonus-points-box.vue'

export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    resourcesList() {
      return [
        {
          name: '通知總覽',
          icon: 'fa-newspaper-o',
          path: '#/user/notifications',
        },
        {
          name: '我的收藏',
          icon: 'fa-heart',
          path: '/user/collections',
        },
        {
          name: '折價票券',
          icon: 'fa-ticket',
          path: '#/user/coupons',
        },
      ]
    },
  },

  // created() {},
  // mounted() {},

  methods: {
    showBonusPointsBox() {
      this.$buefy.modal.open({
        parent: this,
        component: BonusPointsBox,
        width: 450,
      })
    },
  },
}
</script>
