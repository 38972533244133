import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'contacts',
  attributes: [
    'id',
    'name',
    'phone',
    'address',
    'email',
    'description',
    'stories',
    'building_age',
    'has_shaft',
    'building_structure',
    'has_waterproofing',
    'picture',
    'created_at',
    'updated_at',
  ],
  editableAttributes: [
    'name',
    'phone',
    'address',
    'email',
    'description',
    'stories',
    'building_age',
    'has_shaft',
    'building_structure',
    'has_waterproofing',
    'picture',
  ],
}

export default class Contact extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  /**
   * Contact 使用 form data 傳遞資料，因此重新定義 `#save`
   *
   * @param {FormData} formData
   * @returns {Promise} 回傳 response 或 errors
   */
  save(formData) {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), formData)
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, formData)
  }

  // extra methods or helpers here...
}
