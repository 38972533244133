import * as types from './mutation-types'
import SiteConfig from '../../resource_models/site_config'

export const fetchConfigs = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchConfigs')

  return new Promise((resolve, reject) => {
    SiteConfig.all()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchConfigs,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchCustomDisplayConfig = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchCustomDisplayConfig')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchCustomDisplayConfig()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCustomDisplayConfig,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchWebsitePreferences = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchWebsitePreferences')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchWebsitePreferences()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchWebsitePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchStorePreferences = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchStorePreferences')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchStorePreferences()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStorePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateLogo = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'updateLogo')

  return new Promise((resolve, reject) => {
    new SiteConfig()
      .updateLogo(formData)
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateLogo,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateWebsitePreferences = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateWebsitePreferences')

  return new Promise((resolve, reject) => {
    model
      .updateWebsitePreferences()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateWebsitePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateStorePreferences = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateStorePreferences')

  return new Promise((resolve, reject) => {
    model
      .updateStorePreferences()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateStorePreferences,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateMobileQuickLinks = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateMobileQuickLinks')

  return new Promise((resolve, reject) => {
    model
      .updateMobileQuickLinks()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateMobileQuickLinks,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateSiteContacts = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSiteContacts')

  return new Promise((resolve, reject) => {
    model
      .updateSiteContacts()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSiteContacts,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchMobileQuickLinks = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchMobileQuickLinks')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchMobileQuickLinks()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchMobileQuickLinks,
            ref: {
              dispatch,
              commit,
            },
            params: {},
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateRmaConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateRmaConfig')

  return new Promise((resolve, reject) => {
    model
      .updateRmaConfig()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRmaConfig,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateHolidayDatabase = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateHolidayDatabase')

  return new Promise((resolve, reject) => {
    SiteConfig.updateHolidayDatabase()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateHolidayDatabase,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const appPaymentGateways = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'appPaymentGateways')

  return new Promise((resolve, reject) => {
    SiteConfig.appPaymentGateways()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: appPaymentGateways,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateShopComConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateShopComConfig')

  return new Promise((resolve, reject) => {
    model
      .updateShopComConfig()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateShopComConfig,
            ref: {
              dispatch,
              commit,
            },
            params: model,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const updateDataPosition = ({ dispatch, commit }, data) => {
  commit(types.API_REQUEST_START, 'updateDataPosition')

  return new Promise((resolve, reject) => {
    SiteConfig.updateDataPosition(data)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'updateDataPosition')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateDataPosition,
            ref: {
              dispatch,
              commit,
            },
            params: data,
          },
          {
            root: true,
          }
        )

        reject(errors)
      })
  })
}

export const fetchRewardsProgram = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchRewardsProgram')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchRewardsProgram()
      .then(response => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRewardsProgram,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateRewardsProgram = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateRewardsProgram')

  return new Promise((resolve, reject) => {
    model
      .updateRewardsProgram()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRewardsProgram,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
