<template lang="pug">

.vc-order-bonus-info(v-if="isRewardsProgramEnabled")
  span {{ attributeLocaleText('order', 'bonus_reward') }}
  .icon
    i.fa.fa-product-hunt
  span.bonus {{ bonusReward }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {}
  // },
  computed: {
    isCartFlow() {
      return ['cart', 'items_confirmed', 'confirmed'].includes(this.order.order_state)
    },

    isRewardsProgramEnabled() {
      if (this.isCartFlow) return this.isUserSignedIn && Number(this.order.bonus_reward) > 0

      return this.bonusRewardTransition
    },

    bonusReward() {
      if (this.isCartFlow) return this.moneyFromAmount(Math.round(this.order.bonus_reward)).format()

      return this.bonusRewardTransition ? this.bonusRewardTransition.credit_amount : 0
    },

    bonusRewardTransition() {
      return this.$store.getters['userBonusCreditTransactions/all'].find((transaction) => transaction.credit_amount > 0)
    },
  },
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
