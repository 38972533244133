<template lang="pug">

.vc-order-bonus-deduct-checker(v-if="isRewardsProgramEnabled")
  b-field(:label="attributeLocaleText('order', 'bonus_deductible')"
          class="bonus-field")
    b-checkbox(class="bonus-checker"
              type="is-odd"
              v-model="isenableBonusDeduction"
              @input="toggleBonusDeductible($event)")
      span.bonus {{ actionLocaleText('use_bonus_deductible', { x: bonusDeductCredit }) }}
      .price(data-currency="TWD") {{ bonusDeductAmount }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],

  props: {
    order: {
      type: Object,
      required: true,
    },

    enableBonusDeduction: {
      type: Boolean,
      required: true,
      default: () => {
        return false
      },
    },
  },

  data() {
    return {
      isenableBonusDeduction: false,
    }
  },

  computed: {
    isRewardsProgramEnabled() {
      return this.isUserSignedIn && this.order.will_consume_bonus_credits > 0
    },

    bonusDeductCredit() {
      return this.moneyFromAmount(Math.round(this.order.will_consume_bonus_credits)).format()
    },

    bonusDeductAmount() {
      return this.moneyFromAmount(Math.round(this.order.bonus_deduct_amount)).format()
    },
  },

  watch: {
    enableBonusDeduction: {
      handler(newValue) {
        this.isenableBonusDeduction = newValue
      },
    },
  },

  // created() {},
  // mounted() {},

  methods: {
    toggleBonusDeductible(event) {
      this.$emit('update:enableBonusDeduction', event)
    },
  },
}
</script>
