import SlideshowContainer from '../components/common/slideshow-container.vue'
import HotProducts from '../components/product/hot-products.vue'
import SelectProducts from '../components/product_category/select-products.vue'
import MobileQuickLinkWrapper from '../components/common/mobile-quick-link-wrapper.vue'
import InfoLinkUseTypeContainer from '../components/info_link/use-type-container.vue'

import aboutEagle from '../../images/about-eagle.png'
import aboutPicture from '../../images/about-picture.jpg'
import serviceWaterproofing from '../../images/service-waterproofing.png'
import serviceInsulation from '../../images/service-insulation.png'
import serviceOthers from '../../images/service-others.png'
import serviceListArrow from '../../images/service-list-arrow.png'

export default {
  name: 'landing-page-container',

  components: {
    SlideshowContainer,
    HotProducts,
    SelectProducts,
    MobileQuickLinkWrapper,
    InfoLinkUseTypeContainer,
  },

  // mixins: [],

  // props: {},

  // data() {
  //   return {}
  // },

  computed: {
    justForWebpackToImportImages() {
      aboutEagle
      aboutPicture
      serviceWaterproofing
      serviceInsulation
      serviceOthers
      serviceListArrow
    },
  },

  // created() {},

  // mounted() {},

  // methods: {}
}
